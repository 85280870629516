.image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.image-grid img {
    width: 100%;
    height: auto;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
}

.image-grid img:last-child {
    margin-right: 0;
}

.selected-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-image-container {
    position: relative;
    background-color: #fff;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    text-align: center;
}

.selected-image {
    max-width: 100%;
    max-height: 100;
    margin-bottom: 10px;
}

.selected-image-container p {
    margin-top: 10px;
}

.selected-image-container .button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.selected-image-container .button-container button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.selected-image-container .button-container button.delete-button {
    background-color: #e0e0e0;
    color: #333;
}

.selected-image-container .button-container button:hover {
    background-color: #d32f2f;
}

.selected-image-container .button-container button.delete-button:hover {
    background-color: #bdbdbd;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.button-container button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-container button.close-button {
    background-color: #999;
}

.button-container button:hover {
    background-color: #d32f2f;
}

.button-container button.close-button:hover {
    background-color: #999;
}