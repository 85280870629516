@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Rubik", sans-serif;
  color: #777;
}

$light: #fff;
$gray: #c4ccde;
$blue: #95a5a6;

.flex {
  display: flex;
}

.navbar {
  background-color: #f3f3f4;
  padding: 1rem 2rem;
  border-bottom: 1px solid $gray;
  height: 55px;
}
.sidebar {
  width: 13rem;
  height: calc(100vh - 0px);
  min-height: 100%;
  border-right: 1px solid #2f4050;
  background-color: #2f4050;

  ul {
    list-style: none;
    padding: 1rem 0.5rem;
    li {
      margin-bottom: 1rem;

      a {
        color: #90989f;
        text-decoration: none;
        border-radius: 0%;

        &.active {
          color: white;
          background-color: #293846;
          width: 120%;
         
        }
      }
    }
  }
}

.buttonSideBar{
  background-color: #2f4050;
  border: none;
  border-right: 1px solid #2f4050;
  border-left: 1px solid #2f4050;
  color: #90989f;
  text-decoration: none;
  border-radius: 0%;

}

.menuBorderLeft{
  border-left: 5px solid #ffca2c;
}

.breadcrumb {
  margin: 10px;
}
.page-heading {
  border-top: 0;
  padding: 0 10px 20px;
  border-bottom: 1px solid #52a4db !important;
}
.wrapper-content {
  padding: 20px 10px 5px;
}
.page-heading-title {
  margin-left: 10px;
  color: #626262;
}

.page-heading-color {
  background-color: white;
}

.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}

.content-page {
  margin-left: 10px;
  margin-right: 10px;
}

.background-page {
  background-color: #f3f3f4;
}

.nav-header-sidebar {
  padding: 33px 25px;
  height: 159px;
  background-color: #243747;
}

.img-circle {
  border-radius: 100%;
}

.profile-picture {
  width: 62px;
}

.font-bold {
  font-weight: 600;
  color: white;
  font-size: 13px;
}

.text-light {
  color: #a4abb1;
}

.hamburger-menu {
  margin-left: 0px;
  
}

.btn-warning {
  color: #fff;
  background-color: #19A688;
  border-color: #ffc107;
}

.detailprojectTable{
  width: 100%;
  
}

.detailProjectButton{
  text-align: right !important;
}

.detailProjecttr {
  line-height: 35px;
}

.detailProjectbutton {
 text-align: right !important
}

.walk-text {
  flex: right;
  width: 100%;
}

.btn_edition{
  position: relative;
  float: right;
}

.headerButtonCustomer {
  position: relative;
  float: right;
  top: -30px;
}


