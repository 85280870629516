.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #F3F3F4;
    color: white;
    text-align: center;
}

.profile-picture {
    width: 90px;
    /* Ajusta este valor al tamaño deseado */
    height: auto;
    /* Mantiene la relación de aspecto */
    border-radius: 10px;
    /* Ajusta este valor para controlar el redondeo de las esquinas */
}