.mapDetailHeader {
    height: 330px;
    width: 100%;
}


.mapDetailHeader3 {
    height: 330px;
    width: 100%;
}

.headerButton {
    position: relative;
    float:right;
    top:-30px;
}