.gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-item {
    margin: 10px;
    overflow: hidden;
    flex-basis: calc(25% - 20px);
    box-sizing: border-box;
}

.gallery-item img {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.gallery-item img:hover {
    transform: scale(1.05);
}

.selected-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.full-size-image {
    max-width: 100%;
    max-height: 80vh;
    /* object-fit: contain; */
}

.image-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}