.mapDetails_shared {
    height: 800px;
    width: 100%;
}

.popup {
    font-size: 0.9em;
}

.card {
    background: #f8f9fa;
    border-radius: 0.25rem;
}

/* Resto de tu CSS... */

.card-body {
    padding: 1rem;
}

.card-title {
    font-weight: bold;
}

.table {
    margin-bottom: 0;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-block {
    display: block;
    width: 100%;
}

.custom-popup .leaflet-popup-content {
    width: 500px;
    /* Ajusta este valor según tus necesidades */
}


.map-legend {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo blanco translúcido */
    backdrop-filter: blur(10px);
    /* Efecto de desenfoque */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* Borde sutil para mejorar el efecto */
    z-index: 1000;
}

.map-legend h4 {
    margin: 0 0 5px 0;
    font-size: 16px;
    color: #333;
}

.map-legend ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.map-legend ul li {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    display: flex;
    align-items: center;
}

.map-legend .legend-icon {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 5px;
    border: 1px solid #333;
}