.grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    background-color: #ffffff;
    padding: 1px;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: 14px;
    text-align: center;
}

.icon {
   
    width:80px;
    margin: 16px;
}

.file_name{
   text-align: left;
   
    padding: 15px;
}


.document-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.document-container {
    position: relative;
    background-color: #fff;
    padding: 20px;
    width: 90%;
    height: 90%;
    max-width: 1000px;
    max-height: 800px;
    overflow: auto;
    text-align: center;
}

.document-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.document-container button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.document-container button:hover {
    background-color: #d32f2f;
}