.address-input {
    font: inherit;
    position: relative;
    text-indent: 12px;
    top: 8px;
    color: currentColor;
    width: 100%;
    border-color: #808080;
    border: 1;
    border-radius: 5px;
    border-style: dotted solid;
    height: 1.1876em;
    border-width: thin;
    display: block;
    padding: 5px 0px 13px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: normal;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.pac-container {
    z-index: 9999999999999999 !important;
    position: fixed;
}