.mapDetails {
    height: 500px;
    width: 100%;
}
/*
.popup {
    font-size: 0.9em;
}

.card {
    background: #f8f9fa;
    border-radius: 0.25rem;
}

.card-body {
    padding: 1rem;
}

.card-title {
    font-weight: bold;
}
*/

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-block {
    display: block;
    width: 100%;
}

.map-popup-card {
    position: relative;
    display: flex;
    flex-direction: row;
    /* Ensure the color bar is alongside the card content */
}

.color-bar {
    width: 5px;
    background-color: #C70138;
    /* Default color, can be overridden by props */
}

.card-body {
    padding: 15px;
    flex-grow: 1;
    /* Ensure the card body takes up the remaining space */
}

.table th {
    width: 30px;
    /* Adjust as needed */
}

.table td {
    padding-left: 5px;
}

.me-2 {
    margin-right: 5px;
}

.card-title {
    font-weight: bold;
}






.map-legend-canal {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1000;
    max-width: 230px;
    /* Add this line */
}

.map-legend-canal h4 {
    margin: 0 0 5px 0;
    font-size: 16px;
    color: #333;
}

.map-legend-canal ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.map-legend-canal ul li {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    display: flex;
    align-items: center;
}

.map-legend-canal .legend-icon-canal {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #333;
    flex-shrink: 0;
}