.custom-export-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.loader-container {
    position: relative;
    height: 300px;
    /* Ajustar la altura del contenedor según sea necesario */
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#network-container {
    position: relative;
    height: 500px;
    width: 100%;
    background-color: white;
}

#mynetwork {
    width: 100%;
    height: 100%;

    
}

#mynetwork2 {
    height: 100vh;
    /* 100% del alto de la ventana */
    width: 100vw;
    /* 100% del ancho de la ventana */
}

.legend {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: rgba(245, 245, 245, 0.25);
    /* Color de fondo con transparencia para efecto glass */
    backdrop-filter: blur(10px);
    /* Desenfoque del fondo para efecto glass */
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
    /* Asegúrate de que la leyenda se muestre encima del gráfico */
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.legend-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    /* Para que se vea en caso de colores claros */
    margin-right: 10px;
}

.legend-description {
    display: inline;
    font-size: 0.9em;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.top-products-list {
    margin-top: 10px;
    /* Espacio arriba de la lista para separarla de la caja de búsqueda */
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    /* Ajusta según el ancho de tu caja de búsqueda para alinear */
    font-size: 0.8rem;
    /* Letra más pequeña */
    position: absolute;
    /* Posicionamiento absoluto */
    left: 10px;
    /* Alineado a la izquierda, ajusta según necesites */
    top: 100%;
    /* Coloca justo debajo de la caja de búsqueda */
    z-index: 10;
    /* Asegura que se muestre por encima de otros elementos */
}

.top-products-list h3 {
    margin-bottom: 10px;
    color: #333;
    text-align: left;
    /* Alineación del texto a la izquierda */
}

.top-products-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.top-products-list ul li {
    padding: 5px 10px;
    /* Padding más pequeño */
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
}

.top-products-list ul li:last-child {
    border-bottom: none;
}

.top-products-list ul li:hover {
    background-color: #eaeaea;
}