.mapDetailHeader2 {
    height:550px;
    width: 100%;
}


.mapDetailHeader3 {
    height: 550px;
    width: 100%;
}

.mapDetailHeaderProject {
    height: 610px;
    width: 100%;
}

.headerButtonCustomer {
    position: relative;
    float: right;
    top: -5px;
}

.headerButtonCustomerName {
    position: relative;
   
    margin-left: 30px;
    top: -25px;
}

.createdAt{
    font-size: small;
}

.Button{
    position: relative;
        float: right;
}