.Button_new {
    position: relative;
    float: right;
   // top: -5px;
}


.Button{
    //position: relative;
    float: right;
    // top: -5px;
}


/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --white-color: hsl(0, 0%, 100%);
    --black-color: hsl(0, 0%, 0%);

    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font: "Poppins", sans-serif;
    --h1-font-size: 2rem;
    --normal-font-size: 1rem;
    --small-font-size: .813rem;
}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
input,
button {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

a {
    text-decoration: none;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

/*=============== LOGIN ===============*/
.login {
    position: relative;
    height: 100vh;
    display: grid;
    align-items: center;
}

.login__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.login__form {
    position: relative;
    margin-inline: 1.5rem;
    background-color: hsla(0, 0%, 100%, .01);
    border: 2px solid hsla(0, 0%, 100%, .7);
    padding: 2.5rem 1rem;
    color: var(--white-color);
    border-radius: 1rem;
    backdrop-filter: blur(16px);
}

.login__title {
    text-align: center;
    font-size: var(--h1-font-size);
    margin-bottom: 1.25rem;
}

.login__inputs,
.login__box {
    display: grid;
}

.login__inputs {
    row-gap: 1.25rem;
    margin-bottom: 1rem;
}

.login__box {
    grid-template-columns: 1fr max-content;
    column-gap: .75rem;
    align-items: center;
    border: 2px solid hsla(0, 0%, 100%, .7);
    padding-inline: 1.25rem;
    border-radius: 4rem;
}

.login__input,
.login__button {
    border: none;
    outline: none;
}

.login__input {
    width: 100%;
    background: none;
    color: var(--white-color);
    padding-block: 1rem;
}

.login__input::placeholder {
    color: var(--white-color);
}

.login__box i {
    font-size: 1.25rem;
}

.login__check,
.login__check-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login__check {
    margin-bottom: 1rem;
    font-size: var(--small-font-size);
}

.login__check-box {
    column-gap: .5rem;
}

.login__check-input {
    width: 1rem;
    height: 1rem;
    accent-color: var(--white-color);
}

.login__forgot {
    color: var(--white-color);
}

.login__forgot:hover {
    text-decoration: underline;
}

.login__button {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: var(--white-color);
    border-radius: 4rem;
    color: var(--black-color);
    font-weight: 500;
    cursor: pointer;
}

.login__register {
    font-size: var(--small-font-size);
    text-align: center;
}

.login__register a {
    color: var(--white-color);
    font-weight: 500;
}

.login__register a:hover {
    text-decoration: underline;
}

.version {
    text-align: center;
    font-size: var(--h7-font-size);
}

.language-dropdown {
    margin: 1rem auto;
    text-align: center;
}

.language-toggle {
    background-color: hsla(0, 0%, 100%, .5);
    /* Color semitransparente */
    color: var(--black-color);
    border-radius: 4rem;
    border: 2px solid hsla(0, 0%, 100%, .7);
    font-weight: 500;
}

.language-menu {
    background-color: hsla(0, 0%, 100%, .5);
    /* Color semitransparente */
    border-radius: 4rem;
    border: 2px solid hsla(0, 0%, 100%, .7);
}

.language-menu .dropdown-item {
    color: var(--black-color);
    font-weight: 500;
}

.language-menu .dropdown-item:hover {
    background-color: hsla(0, 0%, 100%, .8);
}

/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (min-width: 576px) {
    .login {
        justify-content: center;
    }

    .login__form {
        width: 420px;
        padding-inline: 2.5rem;
    }

    .login__title {
        margin-bottom: 2rem;
    }
}


.fullscreen-calendar {
    background-color: white;
    //height: 100vh;
    /* 100% del alto de la pantalla */
    //width: 100vw;
    /* 100% del ancho de la pantalla */
 } 

 .custom-control-input {
     position: absolute;
     left: -9999px;
     /* Para ocultarlo */
 }

 .custom-control-label::before,
 .custom-control-label::after {
     position: absolute;
     top: .25rem;
     left: -1.5rem;
     display: block;
     width: 1.75rem;
     height: 1rem;
     content: "";
     background-color: #adb5bd;
     /* Color de fondo para 'apagado' */
     border-radius: .5rem;
     transition: background-color .15s ease-in-out;
 }

 .custom-control-label::after {
     background-color: #fff;
     /* Color del círculo deslizante */
     top: 2px;
     left: 0;
     width: 1rem;
     height: 1rem;
     border-radius: .5rem;
     transition: transform .15s ease-in-out;
 }

 .custom-control-input:checked~.custom-control-label::before {
     background-color: #28a745;
     /* Color de fondo para 'encendido' */
 }

 .custom-control-input:checked~.custom-control-label::after {
     transform: translateX(.75rem);
     /* Mueve el círculo a la derecha */
 }

 .custom-switch {
     padding-left: 2.25rem;
     /* Espacio para el interruptor */
 }


 .chat-container {
     display: flex;
     flex-direction: column;
   
 }

 .message {
     margin: 10px;
     padding: 5px;
     border-radius: 10px;
     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 }

 .message.user {
     align-self: flex-start;
     background-color: #f0f0f0;
 }

 .message.assistant {
     align-self: flex-end;
     background-color: #C6DFDF;
     color: #222222;
 }

 .message img {
     max-width: 200px;
     border-radius: 5px;
 }

 .message-info {
     font-size: 0.75rem;
     color: #797979;
     text-align: right;
 }

 .chat-input-form {
     display: flex;
     margin-top: 10px;
 }

 .chat-input {
     flex-grow: 1;
     padding: 10px;
     border: 1px solid #ccc;
     border-radius: 4px;
 }

 .send-button {
     padding: 10px 20px;
     margin-left: 8px;
     background-color: #007bff;
     color: white;
     border: none;
     border-radius: 4px;
     cursor: pointer;
 }

.user-icon {
    width: 30px;
    /* Adjust the size as necessary */
    height: 30px;
    /* Adjust the size as necessary */
    margin-right: 8px;
    /* Gives some space between the icon and the text */
    vertical-align: middle;
    /* Aligns the icon with the middle of the text */
}

.assistant-icon {
    width: 60px;
        /* Adjust the size as necessary */
        height: 60px;
        /* Adjust the size as necessary */
        margin-right: 8px;
        /* Gives some space between the icon and the text */
        vertical-align: middle;
        /* Aligns the icon with the middle of the text */
}