.mapDetailHeader {
    height: 380px;
    width: 100%;
}

.mapDetailHeader2 {
    height: 440px;
    width: 100%;
}

.Button {
    position: relative;
    float: right;
}