.chat-element {
    padding-bottom: 15px; 
}

.img-circle{
width: 38px;
height: 38px;
}

.media-body {
    overflow: hidden;
   
}
.media-body {
    display: block;
    width: auto;
    font-size:14px;
    color: gray;
}
.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
   
}

.chat-element>.pull-left {
    margin-right: 10px px;
}

.proyectocolor {
    color: #7FB3D5;
}
.usernamecolor{
    color: #FFA44C;
}
.m-b-xs {
    margin-bottom: 5px;
}

.text-navy {
    color: #1ab394;
}

.media-left,
.media-right {
    display: table-cell;
    vertical-align: top;
}

.ibox-content {
    background-color: #fff;
    color: inherit;
    padding: 15px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}

.datepicker-container {
    position: absolute;
    z-index: 999;
}


.custom-export-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.progress-wrapper {
    position: relative;
}

.progress-percentage {
    position: absolute;
    right: 0;
    top: 0;
    color: white;
}



.badge {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 20px;
    background-color: #3498db;
    color: #fff;
    font-family: Arial, sans-serif;
}

.category {
    font-weight: bold;
    margin-right: 8px;
    background-color: orange;
    /* Cambio a color naranja */
    padding: 4px 8px;
    /* Ajuste del padding */
    border-radius: 10px;
    /* Redondear esquinas de la categoría */
}

.description {
    font-size: 0.8em;
}

.close-btn {
    border-radius: 50%;
    /* Hace que el botón sea circular */
    padding: 0 6px;
    /* Controla el tamaño del botón */
    line-height: 1;
    /* Asegura que el símbolo de cerrar esté centrado */
    height: 22px;
    /* Altura del botón */
    width: 22px;
    /* Ancho del botón */
}

.badge-container {
    display: inline-flex;
    /* Ajustado a inline-flex para que se adapte al contenido */
    align-items: center;
    font-family: Arial, sans-serif;
    margin-bottom: 5px;
    /* Espaciado entre badges */
}

.badge-category {
    background-color: #17202A;
    /* Naranja */
    color: white;
    padding: 5px 15px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    font-weight: bold;
    font-size: 12px;
}

.badge-name-container {
    display: flex;
    background-color: #F4F6F6;
    /* Azul FCF3CF 7D379E*/
    color: black;
    padding: 5px 15px;
    position: relative;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.badge-name {
    flex-grow: 1;
    margin-right: 30px;
    font-size: 12px;
    /* Asegura espacio para el botón de cerrar */
}

.badge-close-btn {
    position: absolute;
    right: 0px;
    /* Ajusta según necesidades de diseño */
    top: 50%;
    transform: translateY(-50%);
    /* Centra el botón verticalmente */
    background-color: #A04000;
    /* Gris */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 20px;
    width: 30px;
    /* Ajusta al alto del badge-name-container */
    height: 30px;
    /* Igual al ancho para mantenerlo circular */
    border-radius: 50%;
    line-height: 30px;
    /* Ajusta para centrar la 'X' */
}
